<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CANVAS.CASE.MANAGE_CASE_ID')"
        :header-content="$t('CANVAS.CASE.CASE_CONTENT')"
      />
      <new-caseid-form
        :on-submit="onSubmit"
        :case-id="currentCaseId"
        @success="onSuccess"
        @cancel="onCancel"
      />
    </div>
  </woot-modal>
</template>

<script>
import NewCaseidForm from './NewCaseidForm';
import { mapGetters } from 'vuex';

export default {
  components: {
    NewCaseidForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  watch: {},
  mounted() {},
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
    }),
    currentCaseId() {
      return (
        this.$store.getters.getSelectedChat?.additional_attributes
          ?.case_title ?? ''
      );
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
    async onSubmit(formData) {
      const data = await this.$store.dispatch(
        'contactConversations/invite',
        formData
      );
      return data;
    },
  },
};
</script>
