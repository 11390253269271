<template>
  <div class="call-app--container">
    <div
      v-show="uiFlags.isIframeMounted"
      id="JS-Conference"
      class="dashboard-app--frame"
    />
    <p v-if="!uiFlags.isIframeMounted">
      {{ $t('INTEGRATION_SETTINGS.CANVAS.END_CALL_MESSAGE_CLIENT') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import JitsiConference from '../../../dashboard/api/JitsiConference';
import { LocalStorage } from 'shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from '../../../dashboard/constants/localStorage';

export default {
  computed: {
    ...mapGetters({
      uiFlags: 'jitsiCall/getUIFlags',
    }),
    caseToken() {
      return this.$route.query['case-token'];
    },
    conversationId() {
      return this.$route.query?.id;
    },
  },
  mounted() {
    JitsiConference.setIframeForDom('JS-Conference');
    setTimeout(() => this.startMeet(), 1000);
  },
  methods: {
    startMeet() {
      this.$store.dispatch('jitsiCall/update', {
        isIframeMounted: true,
      });
      let agent_token = LocalStorage.get(LOCAL_STORAGE_KEYS.AGENT_TOKEN);
      let displayName = LocalStorage.get(LOCAL_STORAGE_KEYS.AGENT_NAME);
      JitsiConference.setDisplayName(displayName);
      JitsiConference.setCompanyId(this.caseToken);
      JitsiConference.setUid(' ');
      JitsiConference.setConversationId(this.conversationId || '');
      JitsiConference.setHost(Boolean(agent_token));
      JitsiConference.setToken(agent_token ?? '');
      JitsiConference.setMeetingId(this.caseToken);
      JitsiConference.setCaseToken(this.caseToken);
      JitsiConference.defaultMuteVideo(agent_token ? true : false);
      JitsiConference.setUserId('');
      JitsiConference.startMeet(() => {
        LocalStorage.remove(LOCAL_STORAGE_KEYS.AGENT_TOKEN);
        LocalStorage.remove(LOCAL_STORAGE_KEYS.AGENT_NAME);
        this.$store.dispatch('jitsiCall/update', {
          isIframeMounted: false,
        });
        window.close();
      });
    },
  },
};
</script>

<style scoped>
.call-app--container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-app--frame {
  width: 100%;
  height: 100%;
}
.dashboard-app--frame frame {
  width: 100%;
  height: 100%;
}
</style>
