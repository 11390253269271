<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('INVITE_MODAL.HEADER_TITLE')"
        :header-content="$t('INVITE_MODAL.HEADER_CONTENT')"
      />
      <new-invitation-form
        :on-submit="onSubmit"
        @success="onSuccess"
        @cancel="onCancel"
      />
    </div>
  </woot-modal>
</template>

<script>
import NewInvitationForm from './NewInvitationForm';
import { mapGetters } from 'vuex';

export default {
  components: {
    NewInvitationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  watch: {},
  mounted() {},
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
    async onSubmit(formData) {
      const data = await this.$store.dispatch(
        'contactConversations/invite',
        formData
      );
      return data;
    },
  },
};
</script>
