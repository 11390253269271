/* global axios */

import ApiClient from '../../api/ApiClient';

class CanvasAPI extends ApiClient {
  constructor() {
    super('integrations/canvas', { accountScoped: true });
  }

  createAMeeting(conversationId) {
    return axios.post(`${this.url}/create_a_meeting`, {
      conversation_id: conversationId,
    });
  }

  getHistory(caseToken) {
    return axios.post(`${this.url}/history`, {
      case_token: caseToken,
    });
  }

  async getAttachments(conversationId) {
    const { status, data } = await axios.post(`${this.url}/attachments`, {
      conversation_id: conversationId,
    });
    if (status === 200) {
      return data;
    }
    return { status: false, message: 'Something went wrong' };
  }

  getBlinks(conversationId) {
    return axios.post(`${this.url}/blinks`, {
      conversation_id: conversationId,
    });
  }

  getBlink(conversationId, blinkId) {
    return axios.post(`${this.url}/blink`, {
      conversation_id: conversationId,
      blink_id: blinkId,
    });
  }

  getBlinkAttachments(conversationId, blinkId) {
    return axios.post(`${this.url}/blink_attachments`, {
      conversation_id: conversationId,
      blink_id: blinkId,
    });
  }

  async createNewCaseID(payload) {
    const { status, data } = await axios.post(
      `${this.url}/create_case`,
      payload
    );

    if (status === 200) {
      return data;
    }
    return { status: false, message: 'Something went wrong' };
  }

  async checkCase(payload) {
    const { status, data } = await axios.post(
      `${this.url}/check_case`,
      payload
    );

    if (status === 200) {
      return data;
    }
    return { status: false, message: 'Something went wrong' };
  }

  async createNewDataRequest(payload) {
    try {
      const response = await axios.post(`${this.url}/data_request`, payload);
      if (response.status === 200) {
        const { data } = response;
        return { error: false, data };
      }
      return { status: false, message: 'Something went wrong' };
    } catch (error) {
      if (error.response) {
        // Handle Axios response error (non-2xx status code)
        const errorData = error.response.data;
        return {
          error: true,
          message: errorData.error.message,
        };
      }
      return {
        error: true,
        message: this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'),
      };
    }
  }

  updateCaseID(payload) {
    return axios.post(`${this.url}/update-caseid`, payload);
  }

  async getDataRequestTemplates() {
    const { status, data } = await axios.post(
      `${this.url}/data_request_templates`
    );
    if (status === 200) {
      return data;
    }
    return { status: false, message: 'Something went wrong' };
  }

  async getAttachmentZip(conversationId) {
    const { status, data } = await axios.post(`${this.url}/zip`, {
      conversation_id: conversationId,
    });
    if (status === 200) {
      return data;
    }
    return { status: false, message: 'Something went wrong' };
  }
}

export default new CanvasAPI();
