export const LOCAL_STORAGE_KEYS = {
  DISMISSED_UPDATES: 'dismissedUpdates',
  WIDGET_BUILDER: 'widgetBubble_',
  DRAFT_MESSAGES: 'draftMessages',
  COLOR_SCHEME: 'color_scheme',
  DISMISSED_LABEL_SUGGESTIONS: 'labelSuggestionsDismissed',
  AGENT_TOKEN: 'agent_token',
  AGENT_NAME: 'agent_name',
  CURRENT_COMPANY_NAME: 'currentCompany',
  PREVIEW_BLINK_URL: 'previewURL',
  MESSAGE_REPLY_TO: 'messageReplyTo',
};
