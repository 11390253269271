/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }

  sendInvitation(phone, email, assignee_id, inbox_id, country_id, contact_id, language) {
    let payload = {
      phone,
      email,
      assignee_id,
      inbox_id,
      country_id,
      language
    };

    if (contact_id) {
      payload.contact_id = contact_id;
    }
    return axios.post(`${this.url}/invite`, payload);
  }

  sendReinvitation(phone, email, assignee_id, conversation_id, country_id, contact_id, language) {
    let payload = {
      phone,
      email,
      assignee_id,
      conversation_id,
      country_id,
      language
    };
    if (contact_id) {
      payload.contact_id = contact_id;
    }
    return axios.post(`${this.url}/reinvite`, payload);
  }
}

export default new ConversationApi();
