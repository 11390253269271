import types from '../mutation-types';

export const state = {
  theme: 'auto',
};

export const getters = {
  getTheme($state) {
    return $state.theme;
  },
};

export const actions = {
  update: async function toggleTheme({ commit }, { theme }) {
    commit(types.SET_THEME, { theme });
  },
};

export const mutations = {
  [types.SET_THEME](_state, data) {
    _state.theme = data.theme;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
