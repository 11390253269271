import types from '../mutation-types';

export const state = {
  uiFlags: {
    isIframeMounted: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  update: async function toggleIframe({ commit }, { isIframeMounted }) {
    commit(types.SET_JITSI_UI_FLAG, { isIframeMounted });
  },
};

export const mutations = {
  [types.SET_JITSI_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
