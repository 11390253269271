import SettingsContent from '../Wrapper';
const Bot = () => import('./Index.vue');
// const CsmlEditBot = () => import('./csml/Edit.vue');
// const CsmlNewBot = () => import('./csml/New.vue');
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/blinkin-bot'),
      roles: ['administrator'],
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'BlinkIn Bot',
          icon: 'bot',
          showNewButton: false,
        };
      },
      children: [
        {
          path: '',
          name: 'blinkin_bot',
          component: Bot,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
