class JitsiConference {
  constructor() {
    this.uid = undefined;
    this.domID = undefined;
    this.roomID = undefined;
    this.domain = undefined;
    this.displayName = undefined;
    this.muteVideo = false;
    this.userIsHost = false;
    this.userToken = '';
    this.caseToken = '';
    this.timeout = 0;
    this.userId = '';
    this.companyId = 0;
    this.email = '';
    this.conversationId = '';
  }

  setDomain(jitsiDomain) {
    this.domain = jitsiDomain;
  }

  setMeetingId(meetId) {
    this.roomID = meetId;
  }

  setIframeForDom(id) {
    this.domID = id;
  }

  setDisplayName(name) {
    this.displayName = name;
  }

  setEmail(email) {
    this.email = email;
  }

  setCompanyId(companyId) {
    this.companyId = companyId;
  }

  defaultMuteVideo(shouldMuteVideo) {
    this.muteVideo = shouldMuteVideo;
  }

  setUid(uid) {
    this.uid = uid;
  }

  setHost(isHost) {
    this.userIsHost = isHost;
  }

  setToken(token) {
    this.userToken = token;
  }

  setCaseToken(caseToken) {
    this.caseToken = caseToken;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setConversationId(conversationId) {
    this.conversationId = conversationId;
  }

  importJitsiApi() {
    return new Promise(resolve => {
      if (window.JitsiMeetExternalAPI) {
        resolve(window.JitsiMeetExternalAPI);
      } else {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute(
          'src',
          'https://' + this.domain + '/external_api.js'
          // https://meet-dev.blinkin.io/
        );
        head.addEventListener(
          'load',
          // eslint-disable-next-line func-names
          function (event) {
            if (event.target.nodeName === 'SCRIPT') {
              resolve(window.JitsiMeetExternalAPI);
            }
          },
          true
        );
        head.appendChild(script);
      }
    });
  }

  startMeet(handleClientLeft) {
    const options = {
      roomName: this.roomID,
      parentNode: document.getElementById(this.domID),
      interfaceConfigOverwrite: { TOOLBAR_BUTTONS: [] },
      userInfo: {
        uid: this.uid,
        userId: this.userId,
        conversationId: this.conversationId,
        displayName: this.displayName
          ? this.displayName
          : `User-${parseInt(Math.random() * 1e4, 10)}`,
        type: this.userIsHost ? 'agent' : 'client',
        token: this.userToken || null,
        caseToken: this.caseToken,
        origin: window.location.origin,
        openFrom: 'chatwoot',
        country: 'DE',
        companyID: this.companyId,
        // needs to be dynamic 
        menuLogo: window.location.origin?.includes("wilo") ? 
          "https://canvasprodde.blob.core.windows.net/data/3/assets/images/logo/LO_62c7311505c79WseCi.png" :  
          ""
      },
      configOverwrite: this.muteVideo
        ? {
            startWithVideoMuted: true,
            startWithAudioMuted: false,
            prejoinPageEnabled: false,
            disableDeepLinking: true,
            notifications: [],
          }
        : {
            startWithAudioMuted: false,
            prejoinPageEnabled: false,
            disableDeepLinking: true,
            cameraFacingMode: "environment",
            notifications: [],
          },
    };
    this.importJitsiApi()
      .then(JitsiMeetExternalAPI => {
        const api = new JitsiMeetExternalAPI(this.domain, options);

        if (!api) {
          // eslint-disable-next-line no-console
          console.error('Jitsi Meet API library not loaded.');
        }

        api.addListener('videoConferenceJoined', ({ id }) => {
          //   scope.inMeeting = true;
          if (this.userIsHost === true) {
            // scope.isHost = true;
            api.executeCommand('setHost', id);
          }
        });

        api.addEventListener('changeView', () => {
        });

        api.addListener('videoConferenceLeft', () => {
          handleClientLeft();
          let elements = document.querySelectorAll(
            "[id^='jitsiConferenceFrame']"
          );
          elements.forEach(e => e.remove());
          this.muteVideo = false;
          clearTimeout(this.timeout);
        });
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Jitsi Meet API library not loaded.', err);
      });
  }
}

let jitsiConferenceFrame = new JitsiConference();
jitsiConferenceFrame.setDomain(
  process?.env?.VUE_APP_JITSI_URL || 'meet-dev.blinkin.io'
);


export default jitsiConferenceFrame;
