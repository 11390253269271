<template>
  <form class="invitation--form" @submit.prevent="onFormSubmit">
    <div class="row gutter-small">
      <div class="columns">
        <div class="flex gap-2">
          <input
            id="new_contact"
            v-model="contactType"
            class="notification--checkbox"
            type="radio"
            value="new"
            name="contact-type"
          />
          <label for="new_contact">{{ $t('CONTACT_FORM.FORM.NEW') }}</label>
          <input
            id="existing_contact"
            v-model="contactType"
            class="notification--checkbox"
            type="radio"
            value="existing"
            name="contact-type"
          />
          <label for="existing_contact">{{ $t('CONTACT_FORM.FORM.CONTACT_LIST') }}</label>
        </div>
        <div v-if="contactType === 'new'">
          <label>
            {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
            <woot-phone-input
              v-model="phoneNumber"
              :value="phoneNumber"
              :error="isPhoneNumberNotValid"
              :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
              @input="onPhoneNumberInputChange"
              @blur="$v.phoneNumber.$touch"
              @setCode="setPhoneCode"
              @setId="setCountryId"
            />
            <div v-if="isPhoneNumberNotValid" class="error-message">
              {{ phoneNumberError }}
            </div>
          </label>
          <label>
            {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL') }}
            <input
              v-model.trim="email"
              type="text"
              :placeholder="$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.FILL_PLACEHOLDER')"
            />
            <div v-if="isEmailNotValid" class="error-message">
              {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR') }}
            </div>
            <div
              v-if="!(email.length || phoneNumber.length)"
              class="error-message"
            >
              {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.EMAIL_OR_PHONE_ERROR') }}
            </div>
          </label>
        </div>
        <div v-if="contactType === 'existing'">
          <label>{{ $t('CONTACT_FORM.FORM.CONTACT.LABEL') }}</label>
          <multiselect
            v-model="selectedContact"
            :options="contactOptions"
            track-by="id"
            label="name"
            :close-on-select="true"
            :loading="contactsFetching.isInviteContactFetching"
            @select="handleContactSelect"
            :placeholder="$t('CONTACT_FORM.FORM.CONTACT.PLACEHOLDER')"
          />
          <div
            v-if="selectedContact && selectedContact.phoneNumber"
            class="flex gap-2"
          >
            <input
              id="phoneNumber"
              v-model="preferredContactMethod"
              class="notification--checkbox"
              type="radio"
              value="phoneNumber"
              name="contact"
            />
            <label for="phoneNumber">
              {{$t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL')}}: {{ selectedContact.phoneNumber }}
            </label>
          </div>
          <div
            v-if="selectedContact && selectedContact.email"
            class="flex gap-2"
          >
            <input
              id="email"
              v-model="preferredContactMethod"
              class="notification--checkbox"
              type="radio"
              value="email"
              name="contact"
            />
            <label for="email">
              {{$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL')}}: {{ selectedContact.email }}
            </label>
          </div>
        </div>
        <label>
        </label>
        <div class="w-full">
          <label>
            {{ $t('CONVERSATION.MESSAGE_COUNTRY') }}
          </label>
          <multiselect
            v-model="country"
            track-by="id"
            label="name"
            :placeholder="$t('CONTACT_FORM.FORM.COUNTRY.PLACEHOLDER')"
            selected-label
            :select-label="$t('CONTACT_FORM.FORM.COUNTRY.SELECT_PLACEHOLDER')"
            :deselect-label="$t('CONTACT_FORM.FORM.COUNTRY.REMOVE')"
            :custom-label="countryNameWithCode"
            :max-height="160"
            :options="filteredCountries"
            :allow-empty="false"
            :option-height="104"
            @input="onCountryChange"
          />
        </div>
        <label>
          <div v-if="!country.id.length" class="error-message">
            {{ $t('CONTACT_FORM.FORM.COUNTRY.REQUIRED') }}
          </div>
        </label>
        <!-- <label>
          {{ $t('CONVERSATION.MESSAGE_LANGUAGE') }}
          <select v-model="locale" @change="onLanguageChange">
            <option
              v-for="lang in languagesSortedByCode"
              :key="lang.iso_639_1_code"
              :value="lang.iso_639_1_code"
            >
              {{ lang.name }}
            </option>
          </select>
        </label> -->
        <label>
          {{ $t('CONTACT_FORM.FORM.CASE.LABEL') }}
          <input
            v-model.trim="caseID"
            type="text"
            :placeholder="$t('CONTACT_FORM.FORM.CASE.PLACEHOLDER')"
          />
          <div v-if="caseID.length && caseID.length < 2" class="error-message">
              {{ $t('CONTACT_FORM.FORM.CASE.LENGTH_ERROR') }}
          </div>
          <div v-if="caseIdError" class="error-message">
            {{ caseIdError }}
          </div>
        </label>
        <label>
        {{ $t('CONTACT_FORM.FORM.INBOX.LABEL') }}
          <select v-model="inboxID" :placeholder="$t('CONTACT_FORM.FORM.INBOX.PLACEHOLDER')">
            <option value="" disabled>{{ $t('CONTACT_FORM.FORM.INBOX.PLACEHOLDER') }}</option>
            <option
              v-for="inbox in webTypeInboxes"
              :key="inbox.id"
              :value="inbox.id"
            >
              {{ inbox.name }}
            </option>
          </select>
        </label>
        <!-- Display error message for validation -->
        <div v-if="!inboxID && isInboxEdited" class="error-message">
          {{ $t('CONTACT_FORM.FORM.INBOX.SELECT_INBOX') }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button clear" @click.prevent="onCancel">
        {{ $t('NEW_CONVERSATION.FORM.CANCEL') }}
      </button>
      <woot-button
        :disabled="
          (contactType === 'new' &&
            (isPhoneNumberNotValid ||
              isEmailNotValid ||
              !(email.length || phoneNumber.length))) ||
          (contactType === 'existing' && !preferredContactMethod) ||
          (caseID.length && caseID.length < 2) ||
          (caseIdError.length > 1) ||
          !inboxID || country.id.length === 0
        "
        type="submit"
        :is-loading="isLoading"
      >
        {{ $t('NEW_CONVERSATION.FORM.SUBMIT') }}
      </woot-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import CanvasAPI from 'dashboard/canvas/api/canvas';
import { isPhoneNumberValid } from 'shared/helpers/Validators';
import parsePhoneNumber from 'libphonenumber-js';
import configMixin from 'shared/mixins/configMixin';
import countries from 'shared/constants/countries';

export default {
  components: {},
  mixins: [alertMixin, configMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      phoneNumber: '',
      email: '',
      caseID: '',
      caseIdError: '',
      inboxID: '',
      phoneInput: '',
      isInboxEdited: false,
      activeDialCode: '',
      isLoading: false,
      failedToCreateCase: false,
      isValidPhone: true,
      countryId: 'all',
      contactType: 'new',
      preferredContactMethod: '',
      selectedContact: null,
      locale: 'en',
      selectedCountry: '',
      countries: countries,
      country: {
        id: '',
        name: '',
      },
    };
  },
  validations: {
    caseID: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
    inboxID: {
      required,
      minLength: minLength(1),
    },
    phoneNumber: {},
    existingContact: {
      required,
    },
    locale: {},
  },
  computed: {
    ...mapGetters({
      conversationsUiFlags: 'contactConversations/getUIFlags',
      inviteContacts: 'contacts/getContactsForInvite',
      contactsFetching: 'contacts/getUIFlags',
      currentUser: 'getCurrentUser',
      currentChat: 'getSelectedChat',
      inboxes: 'inboxes/getInboxes',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
    filteredCountries() {
      const account = this.getAccount(this.currentAccountId);
      if (account.countries.length == undefined || account.countries.length === 0) {
        return this.countries;
      }

      // Otherwise, filter countries based on account.countries
      const filtered = this.countries.filter(country => account.countries.includes(country.id));
      return filtered;
    },

    webTypeInboxes() {
      const filteredInboxes = this.inboxes.filter(
        inbox => inbox.channel_type === 'Channel::WebWidget'
      );
      if (filteredInboxes?.length === 1) {
        this.inboxID = filteredInboxes[0]?.id;
      }
      return filteredInboxes;
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    contactOptions() {
      return this.inviteContacts.map(contact => ({
        id: contact.id,
        name: contact.name,
        phoneNumber: contact.phone_number,
        email: contact.email,
      }));
    },
    payload() {
      const payload = {
        // countryId: this.countryId,
        language: this.locale,
        countryId: this.country.id,
        phone:
          this.contactType === 'existing' &&
          this.preferredContactMethod === 'phoneNumber'
            ? this.selectedContact.phoneNumber
            : this.contactType === 'new'
            ? this.setPhoneNumber
            : '',
        email:
          this.contactType === 'existing' &&
          this.preferredContactMethod === 'email'
            ? this.selectedContact.email
            : this.contactType === 'new'
            ? this.email
            : '',
        assigneeId: this.currentUser.id,
        inboxId: this.inboxID,
      };
      if (this.contactType === 'existing') {
        payload.contact_id = this.selectedContact.id;
      }
      return payload;
    },
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },
    isPhoneNumberNotValid() {
      if (this.phoneNumber !== '') {
        return (
          !isPhoneNumberValid(this.phoneNumber, this.activeDialCode) ||
          (this.phoneNumber !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },
    isEmailNotValid() {
      if (this.email !== '') {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return !emailRegex.test(this.email);
      }
      return false;
    },
    phoneNumberError() {
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isPhoneNumberValid(this.phoneNumber, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
    setPhoneNumber() {
      if (this.parsePhoneNumber && this.parsePhoneNumber.countryCallingCode) {
        return this.phoneNumber;
      }
      if (this.phoneNumber === '' && this.activeDialCode !== '') {
        return '';
      }
      return this.activeDialCode
        ? `${this.activeDialCode}${this.phoneNumber}`
        : '';
    },
  },
  watch: {
    inboxID(id) {
      if (id) {
        this.isInboxEdited = true;
      }
    },
    caseID(id) {
      this.caseIdError = this.$t('CONTACT_FORM.FORM.CASE.VALIDATING')
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.checkIfCaseIdExists(id);
      }, 1000);
    },
    contactType(type) {
      if (type === 'new') {
        this.selectedContact = null;
        this.preferredContactMethod = '';
      }
      if (type === 'existing') {
        this.email = '';
        this.phoneNumber = '';
        this.fetchContacts();
      }
    },
  },
  mounted() {
    const lastActiveCountryCode = localStorage.getItem('activeLanguageCode');
    if (lastActiveCountryCode !== '' && lastActiveCountryCode !== null) {
      this.locale = lastActiveCountryCode;
    }
    const lastSelectedCountryId = localStorage.getItem('selectedCountryId');
    if (lastSelectedCountryId) {
      // Find the corresponding country object from your options
      const selectedCountry = this.countries.find(country => country.id === lastSelectedCountryId);
      if (selectedCountry) {
        this.country = selectedCountry;
      }
    }
    this.setDialCode();
  },
  methods: {
    updateLoadingStatus(status) {
      this.isLoading = status;
    },
    countryNameWithCode({ name, id, emoji }) {
      if (!id) return name;
      if (!name && !id) return '';
      return `${emoji}  ${name} (${id})`;
    },
    async checkIfCaseIdExists(case_title) {
      try {
        if (case_title?.length < 2) {
          return this.caseIdError = '';
        };
        const casePayload = {
          case_title,
          account_id: this.currentUser.id,
        };
        const data = await CanvasAPI.checkCase(casePayload);
        if (data?.caseExists) {
          return this.caseIdError = this.$t('CONTACT_FORM.FORM.CASE.ALREADY_EXIST');
        }
        this.caseIdError = "";
      } catch (error) {
        this.caseIdError = this.$t('CONTACT_FORM.FORM.CASE.INVALID');
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    fetchContacts() {
      const requestParams = {
        page: undefined,
        sortAttr: 'name',
      };
      this.$store.dispatch('contacts/getAll', requestParams);
    },
    setDialCode() {
      if (
        this.phoneNumber !== '' &&
        this.parsePhoneNumber &&
        this.parsePhoneNumber.countryCallingCode
      ) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        this.activeDialCode = `+${dialCode}`;
      }
    },
    onLanguageChange(event) {
      const selectedLanguage = event.target.value;
      localStorage.setItem('activeLanguageCode', selectedLanguage);
    },
    onCountryChange(selectedCountry) {
      const selectedCountryId = selectedCountry.id;
      localStorage.setItem('selectedCountryId', selectedCountryId);
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    setCountryId(id) {
      this.countryId = id;
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phoneNumber.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    onFormSubmit() {
      if (
        this.contactType === 'new' &&
        !(this.email.length || this.phoneNumber.length)
      ) {
        return;
      }
      // Check for country validation
      if (!this.country.id.length) {
        return;
      }
      if (this.caseID && this.caseID.length < 2) return;
      this.createInvitation(this.payload);
    },
    handleContactSelect(selectedOption) {
      if (selectedOption?.email) {
        this.preferredContactMethod = 'email';
        return;
      }
      if (selectedOption?.phoneNumber) {
        this.preferredContactMethod = 'phoneNumber';
        return;
      }
    },
    generateFormattedString(data) {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = String(currentDate.getFullYear()).slice(-2); // Get last two digits of the year
      const caseName = `ID-${data.id}/${month}${day}${year}-${data.account_id}-${data.inbox_id}`;
      return caseName;
    },
    disableButton() {
      return !this.$v.caseID.minLength || !this.$v.caseID.maxLength;
    },
    // handleInput(e) {
    //   this.contactType = e.target.value;
    // },
    async createInvitation(payload) {
      try {
        // let currentInboxId = this.$route?.params?.inbox_id ?? 0;
        this.updateLoadingStatus(true);
        const { data } = await this.onSubmit({
          ...payload,
          // inboxId: currentInboxId,
        });
        const currentInbox = this.webTypeInboxes.find(
          inbox => inbox.id === data.inbox_id
        );
        // const currentInboxName = currentInbox ? currentInbox.name : 'blinkin';
        const newCaseId =
          this.caseID || this.generateFormattedString(data);

        const casePayload = {
          case_title: newCaseId,
          account_id: this.currentUser.id,
          conversation_id: data.id,
        };
        try {
          await CanvasAPI.createNewCaseID(casePayload);
        } catch (error) {
          this.updateLoadingStatus(false);
          const errorMessage =
            error?.response?.data?.error?.message || 'CANVAS.ERROR';
          this.showAlert(this.$t(errorMessage));
          return;
        }

        const action = {
          type: 'link',
          to: `/app/accounts/${data.account_id}/conversations/${data.id}`,
          message: this.$t('NEW_CONVERSATION.FORM.GO_TO_CONVERSATION'),
        };

        this.$store.dispatch('getConversation', data.id);
        this.updateLoadingStatus(false);
        this.onSuccess();
        if (this.failedToCreateCase) {
          this.showAlert(this.$t('CANVAS.CASE.ERROR'));
        } else {
          this.showAlert(this.$t('CANVAS.CASE.CREATED'), action);
        }
      } catch (error) {
        this.updateLoadingStatus(false);
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.invitation--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);
}

.canned-response {
  position: relative;
}

.input-group-label {
  font-size: var(--font-size-small);
}

.contact-input {
  display: flex;
  align-items: center;
  height: 3.9rem;
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
  padding: var(--space-smaller) var(--space-small);
  border-radius: var(--border-radius-small);

  .contact-name {
    margin: 0;
    margin-left: var(--space-small);
    margin-right: var(--space-small);
  }
}

.message-input {
  min-height: 8rem;
}

.row.gutter-small {
  gap: var(--space-small);
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.error-message {
  margin-top: -10px;
  font-size: 12px;
  color: red;
}
</style>
