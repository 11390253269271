<template>
  <form class="invitation--form" @submit.prevent="onFormSubmit">
    <div class="row gutter-small">
      <div class="columns">
        <label>
            {{ $t('CANVAS.CASE.CASE_ID') }}
          <input
            v-model.trim="caseID"
            type="text"
            :placeholder="$t('CANVAS.CASE.CASE_ID_PLACEHOLDER')"
          />
        </label>
        <!-- Display error message for validation -->
        <div v-if="!$v.caseID.required" class="error-message">
          {{ $t('CANVAS.CASE.CASE_ID_REQUIRE') }}
        </div>
        <div v-if="!$v.caseID.minLength" class="error-message">
          {{ $t('CANVAS.CASE.CASE_ID_MIN_VALIDATION') }}
        </div>
        <div v-if="!$v.caseID.maxLength" class="error-message">
          {{ $t('CANVAS.CASE.CASE_ID_MAX_VALIDATION') }}
        </div>
        <div v-if="caseIdError" class="error-message">
          {{ caseIdError }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button clear" @click.prevent="onCancel">
        {{ $t('NEW_CONVERSATION.FORM.CANCEL') }}
      </button>
      <woot-button type="submit" :is-disabled="!!caseIdError" :is-loading="isLoading">
        <template v-if="caseId"> {{ $t('CONVERSATION.UPDATE_CASE_ID') }} </template>
        <template v-else> {{ $t('CONVERSATION.CREATE_CASE_ID') }} </template>
      </woot-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import CanvasAPI from 'dashboard/canvas/api/canvas';

export default {
  mixins: [alertMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    caseId: {
      // Additional prop to receive the prefill value
      type: String,
      default: '', // Set a default value if the prop is not provided
    },
    conversationId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      caseID: '',
      isLoading: false,
      caseIdError: '',
    };
  },
  validations: {
    caseID: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
  },
  computed: {
    ...mapGetters({
      conversationsUiFlags: 'contactConversations/getUIFlags',
      currentUser: 'getCurrentUser',
      currentChat: 'getSelectedChat',
    }),
    payload() {
      const payload = {
        case_title: this.caseID,
        account_id: this.currentUser.id,
        conversation_id: this.currentChat.id,
      };
      return payload;
    },
  },
  watch: {
    caseId: {
      immediate: true, // Run the watcher immediately when the component is mounted
      handler(newVal) {
        // Set the initial value of caseID from the prop caseId
        this.caseID = newVal || ''; // Use the prop value or an empty string if not provided
      },
    },
    caseID(id) {
      this.caseIdError = this.$t('CANVAS.CASE.CASE_ID_VALIDATING');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.checkIfCaseIdExists(id);
      }, 1000);
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    async checkIfCaseIdExists(case_title) {
      try {
        if (case_title?.length < 2) {
          return this.caseIdError = '';
        };
        if (this.caseId.length && this.caseID === case_title) {
          return this.caseIdError = '';
        };
        const casePayload = {
          case_title,
          account_id: this.currentUser.id,
        };
        const data = await CanvasAPI.checkCase(casePayload);
        if (data?.caseExists) {
          return this.caseIdError = 'Case ID already exists.';
        }
        this.caseIdError = '';
      } catch (error) {
        this.caseIdError = 'Invalid case ID.';
      }
    },
    updateLoadingStatus(status) {
      this.isLoading = status;
    },
    async onFormSubmit(event, allowMultiple = false) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.updateLoadingStatus(true);

        const data = await CanvasAPI.createNewCaseID({
          ...this.payload,
          allow_multiple: allowMultiple,
        });

        if (data.status) {
          this.showAlert(this.$t(data.message || 'CANVAS.ERROR'));

          this.onSuccess();
        } else {
          this.showAlert(this.$t(data.message || 'CANVAS.ERROR'));
        }
        this.updateLoadingStatus(false);
        this.$store.dispatch('getConversation', this.currentChat.id);
      } catch (error) {
        this.updateLoadingStatus(false);

        const errorMessage =
          error?.response?.data?.error?.message || 'CANVAS.ERROR';
        this.showAlert(this.$t(errorMessage));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.invitation--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);
}

.error-message {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 12px;
  color: red;
}

.canned-response {
  position: relative;
}

.input-group-label {
  font-size: var(--font-size-small);
}

.contact-input {
  display: flex;
  align-items: center;
  height: 3.9rem;
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
  padding: var(--space-smaller) var(--space-small);
  border-radius: var(--border-radius-small);

  .contact-name {
    margin: 0;
    margin-left: var(--space-small);
    margin-right: var(--space-small);
  }
}

.message-input {
  min-height: 8rem;
}

.row.gutter-small {
  gap: var(--space-small);
}
</style>
